import React from "react";
import PropTypes from "prop-types";

import { Colors } from "container/global.colors";

export const LinkedIn = ({ height, width, ...rest }) => {
    return (
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100"  {...rest}>
            <circle cx="50" cy="50" r="50" fill="currentColor" stroke-width="0"/>
            <path
                d="M37.176,71.353h-10.059v-32.412h10.059v32.412ZM32.118,34.471c-3.235,0-5.882-2.588-5.882-5.824s2.588-5.882,5.824-5.882,5.882,2.588,5.882,5.824h0c0,3.235-2.588,5.882-5.824,5.882ZM74.824,71.353h-10.059v-15.765c0-3.765-.059-8.588-5.235-8.588s-6.059,4.059-6.059,8.294v16.059h-10.059v-32.412h9.647v4.412h.118c1.353-2.529,4.647-5.235,9.529-5.235,10.176,0,12.059,6.706,12.059,15.412v17.824h.059Z"
                fill="#063773" stroke-width="0"/>
        </svg>
    );
};

LinkedIn.defaultProps = {
    color: Colors.WHITE_FFFFFF,
    height: "20px",
    width: "20px",
};

LinkedIn.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default LinkedIn;
