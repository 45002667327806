import React, { Fragment, useContext } from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";

import { ContentRow } from "components/content-row/content-row";

import { Colors } from "container/global.colors";

import { DeviceOutputContext } from "context/device-output.context";

import { Check24Hilft } from "icons/check24-hilft";
import { Facebook } from "icons/facebook";
import { LinkedIn } from "icons/linkedin";
import { Instagram } from "icons/Instagram";
import { Twitter } from "icons/twitter";
import { Youtube } from "icons/youtube";

import { Link } from "utils/link";
import { Markdown } from "utils/markdown";

import Styles from "./footer.styles";

export const Footer = ({ global, categories }) => {
    const { donateLinkUrl, footer } = global;
    const { note, copyright, dataProtectionUrl, facebookUrl, twitterUrl, instagramUrl, youtubeUrl, linkedinUrl } = footer;
    const { isApp } = useContext(DeviceOutputContext);

    categories.sort((a, b) => {
        if (a.order > b.order) {
            return -1;
        }
        if (b.order > a.order) {
            return 1;
        }
        return 0;
    });

    return (
        <>
            <ContentRow style={{ alignSelf: "flex-end" }}>
                <Styles.Nav>
                    {categories
                        .map((category, i) => {
                            if (!category.withOverview) return;

                            // eslint-disable-next-line consistent-return
                            return (
                                category.displayName && (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Fragment key={i}>
                                        <NextLink
                                            as={`/category/${category.slug}`}
                                            key={category.id}
                                            href="/category/[slug]"
                                        >
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a title={category.displayName}>
                                                <Markdown>{category.displayName}</Markdown>
                                            </a>
                                        </NextLink>

                                        <span> | </span>
                                    </Fragment>
                                )
                            );
                        })
                        .slice(0, -1)}

                    <Link href={donateLinkUrl} target="_blank" title="Punkte spenden">
                        Punkte spenden
                    </Link>

                    <span> | </span>

                    <Link href="https://jobs.check24.de/" target="_blank" title="Karriere">
                        Karriere
                    </Link>

                    <span> | </span>

                    <NextLink as="/category/impressum" href="/category/[slug]">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a title="Impressum">Impressum</a>
                    </NextLink>

                    <span> | </span>

                    <Link href={dataProtectionUrl} target="popup" title="Datenschutz">
                        Datenschutz
                    </Link>
                </Styles.Nav>

                <Styles.AntiDiscrimination>{note}</Styles.AntiDiscrimination>

                <Styles.Copyright>{copyright}</Styles.Copyright>
            </ContentRow>

            <footer>
                {isApp === false && (
                    <ContentRow backgroundColor={Colors.BLUE_063773} style={{ alignSelf: "flex-end" }}>
                        <Styles.Row>
                            <NextLink href="/">
                                <Styles.LogoWrapper>
                                    <Check24Hilft height="45px" width="200px" />
                                </Styles.LogoWrapper>
                            </NextLink>

                            <Styles.SocialMedia>
                                {facebookUrl && (
                                    <Styles.SocialMediaLink href={facebookUrl} title="Facebook" target="_blank">
                                        <Facebook height="40px" width="40px" />
                                    </Styles.SocialMediaLink>
                                )}

                                {instagramUrl && (
                                    <Styles.SocialMediaLink href={instagramUrl} title="Instagram" target="_blank">
                                        <Instagram height="40px" width="40px" />
                                    </Styles.SocialMediaLink>
                                )}

                                {linkedinUrl && (
                                    <Styles.SocialMediaLink href={linkedinUrl} title="LinkedIn" target="_blank">
                                        <LinkedIn height="40px" width="40px" />
                                    </Styles.SocialMediaLink>
                                )}

                                {twitterUrl && (
                                    <Styles.SocialMediaLink href={twitterUrl} title="Twitter" target="_blank">
                                        <Twitter height="40px" width="40px" />
                                    </Styles.SocialMediaLink>
                                )}

                                {youtubeUrl && (
                                    <Styles.SocialMediaLink href={youtubeUrl} title="Youtube" target="_blank">
                                        <Youtube height="40px" width="40px" />
                                    </Styles.SocialMediaLink>
                                )}
                            </Styles.SocialMedia>
                        </Styles.Row>
                    </ContentRow>
                )}
            </footer>
        </>
    );
};

Footer.propTypes = {
    global: PropTypes.shape({
        donateLinkUrl: PropTypes.string.isRequired,
        footer: PropTypes.shape({
            note: PropTypes.string.isRequired,
            copyright: PropTypes.string.isRequired,
            dataProtectionUrl: PropTypes.string.isRequired,
            facebookUrl: PropTypes.string,
            linkedinUrl: PropTypes.string,
            twitterUrl: PropTypes.string,
            instagramUrl: PropTypes.string,
            youtubeUrl: PropTypes.string,
        }).isRequired,
    }).isRequired,
    categories: PropTypes.any.isRequired,
};

export const PopupFooter = ({ global }) => {
    const { footer } = global;
    const { note, copyright } = footer;

    return (
        <ContentRow style={{ alignSelf: "flex-end" }}>
            <Styles.AntiDiscrimination>{note}</Styles.AntiDiscrimination>

            <Styles.Copyright>{copyright}</Styles.Copyright>
        </ContentRow>
    );
};

PopupFooter.propTypes = {
    global: PropTypes.shape({
        footer: PropTypes.shape({
            note: PropTypes.string.isRequired,
            copyright: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default Footer;
